import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.environment,
    release: 'sahelp-applicant@' + config.public.clientVersion,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
      new Sentry.Replay(),
    ],
    enabled: config.public.sentryEnabled,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'NetworkError',
      /Failed to fetch/,
    ],
    beforeSend(event: any, hint: any) {
      // Check if the error is from a browser extension
      if (event.exception && event.exception.values && event.exception.values[0]) {
        if (event.exception.values[0].stacktrace && event.exception.values[0].stacktrace.frames) {
          const topFrame = event.exception.values[0].stacktrace.frames.pop();
          if (topFrame && topFrame.filename &&
            (topFrame.filename.startsWith('chrome-extension://') ||
              topFrame.filename.startsWith('moz-extension://'))) {
            return null; // Drop the event
          }
        }
      }


      // Check for specific error messages
      const ignoredMessages = [
        'NetworkError when attempting to fetch resource.',
        'Failed to fetch',
        'AbortError',
        'ERR_BLOCKED_BY_CLIENT',
        'NS_ERROR_ABORT'
        // Add other messages here
      ];
      if (event.message && ignoredMessages.some(msg => event.message.includes(msg))) {
        return null;
      }

      //check the url origin.
      if (event.request && event.request.url && event.request.url.indexOf('badurl') > 0){
        return null;
      }

      // Otherwise, send the event
      return event;
    },
  });
});
