export const maxValidDays = (node) => {
    // Get the selected date value
    const selectedDate = new Date(node.value);

    // Get the number of days to compare against

    const allowedDays = parseInt(node.props?.parsedRules.find(rule => rule.name === 'maxValidDays')?.args[0] ?? '0');

    // Get current date (reset time to midnight for fair comparison)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const differenceInTime = selectedDate.getTime() - today.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    console.log(differenceInDays)
    // Return true if valid (selected date is within allowed days)
    return differenceInDays <= allowedDays;
}

export const minValidDays = (node) => {
    // Get the selected date value
    const selectedDate = new Date(node.value);

    // Get the number of days to compare against

    const allowedDays = (parseInt(node.props?.parsedRules.find(rule => rule.name === 'minValidDays')?.args[0] ?? '0') * -1);

    // Get current date (reset time to midnight for fair comparison)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const differenceInTime = selectedDate.getTime() - today.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    // Return true if valid (selected date is within allowed days)
    return differenceInDays >= allowedDays;
}
