import validate from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/vsts/work/1/s/packages/sa-help/middleware/auth.ts"),
  "volunteer-auth": () => import("/home/vsts/work/1/s/packages/sa-help/middleware/volunteer-auth.ts"),
  "volunteer-disaster-auth": () => import("/home/vsts/work/1/s/packages/sa-help/middleware/volunteer-disaster-auth.ts")
}