import { defineStore } from 'pinia';
import { ServiceArea } from '~/composables/types/ServiceArea';
import { ZipCode } from '~/composables/types/ZipCode';

export const useServiceAreaStore = defineStore(
  'service-area',
  () => {
    const chosen = ref<ServiceArea>({
      id: '',
      info_url: '',
      welcome_message: '',
      name: '',
      video_url: '',
      welcome_title: '',
      contact_phone: '',
      contact_email: '',
      license_type_id: 1,
      active: true,
      toggle_active_date: '',
      location_id: 0,
      zipcodes: [],
      question: '',
      restricted: false,
      hasActiveNonEmptyFund: true,
      fund_service_areas: [{ fund: { tbl_fund_assistance_types: [], funding_source: { name: '' } } }],
      is_max_application: false,
      new_application_message: '',
    });
    const listServiceAreas = ref<ServiceArea[]>([
      {
        id: '',
        info_url: '',
        welcome_message: '',
        name: '',
        video_url: '',
        welcome_title: '',
        contact_phone: '',
        contact_email: '',
        location_id: 0,
        license_type_id: 1,
        active: true,
        toggle_active_date: '',
        zipcodes: [],
        question: '',
        fund_service_areas: [{ fund: { tbl_fund_assistance_types: [], funding_source: { name: '' } } }],
      },
    ]);
    const zipcodeData = ref<ZipCode>({
      state: '',
      stateCode: '',
      zipcode: '',
    });
    const associatedFields = ref<any[]>([]);
    const setAssociatedFields = (fields: any[]) => {
      associatedFields.value = fields;
    }
    const chosenAssistanceTypes = ref<string[]>([]);
    const chosenAssistanceTypesLabel = ref<string | undefined>('');

    const setChosen = (serviceArea: ServiceArea) => {
      chosen.value = serviceArea;
    };

    const setZipcodeData = (zipcode: ZipCode) => {
      zipcodeData.value = zipcode;
    };

    const setChosenAssistanceTypes = (assistanceTypes: string[]) => {
      chosenAssistanceTypes.value = assistanceTypes;
    };

    const setChosenAssistanceTypesLabel = (assistanceTypes: string | undefined) => {
      chosenAssistanceTypesLabel.value = assistanceTypes;
    };

    const setListServiceAreas = (serviceAreas: ServiceArea[]) => {
      listServiceAreas.value = serviceAreas;
    };

    const clear = () => {
      chosen.value = {
        id: '',
        info_url: '',
        welcome_message: '',
        name: '',
        video_url: '',
        welcome_title: '',
        contact_phone: '',
        contact_email: '',
        license_type_id: 1,
        active: true,
        toggle_active_date: '',
        zipcodes: [],
        location_id: 0,
        question: '',
        fund_service_areas: [{ fund: { tbl_fund_assistance_types: [], funding_source: { name: '' } } }],
      };
      zipcodeData.value = {
        state: '',
        stateCode: '',
        zipcode: '',
      };
      chosenAssistanceTypes.value = [];
      chosenAssistanceTypesLabel.value = '';
    };

    const hasActiveNonEmptyFunds = (id: string) => {
      const config = useRuntimeConfig();
      return $fetch<{ success: boolean; data: boolean }>(`api/v1/zipcodes/${id}/has-active-non-empty-funds`, {
        ...globalFetchOptions(),
        baseURL: config.public.apiBase,
        method: 'get',
      });
    };

    return {
      chosen,
      zipcodeData,
      chosenAssistanceTypes,
      setChosen,
      setZipcodeData,
      setChosenAssistanceTypes,
      clear,
      listServiceAreas,
      setListServiceAreas,
      setChosenAssistanceTypesLabel,
      chosenAssistanceTypesLabel,
      hasActiveNonEmptyFunds,
      associatedFields,
      setAssociatedFields,
    };
  },
  {
    persist: true,
  }
);
