import { default as detailsrA0YqXnhFUMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/details.vue?macro=true";
import { default as indexV42NTTu3HZMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/index.vue?macro=true";
import { default as _91app_93wBoh8hjQL2Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/disaster/[id]/[app].vue?macro=true";
import { default as index1speK48e9AMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/disaster/[id]/index.vue?macro=true";
import { default as _91app_93OoHP5pWWgJMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/food/[id]/[app].vue?macro=true";
import { default as indexZcvaTMBTaOMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/food/[id]/index.vue?macro=true";
import { default as list3hQ8kOW9s8Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/list.vue?macro=true";
import { default as detailsI77458fw6KMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/details.vue?macro=true";
import { default as distribution_45detailJ0EdfLGwhJMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/distribution-detail.vue?macro=true";
import { default as distributionqNNK7qjN89Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/distribution.vue?macro=true";
import { default as indexNsyUd4ryc8Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/index.vue?macro=true";
import { default as match5IfnNruwtNMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/match.vue?macro=true";
import { default as searchX4CLnyxpOeMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/search.vue?macro=true";
import { default as submit_45distributionUijYuvPvFPMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/submit-distribution.vue?macro=true";
import { default as email_45change_45erroregTD06NqcMMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/email-change-error.vue?macro=true";
import { default as detailsFEsPO1hbTyMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/details.vue?macro=true";
import { default as distribution_45detailF9b6cTF1WyMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution-detail.vue?macro=true";
import { default as distributionPQJOtyuW23Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution.vue?macro=true";
import { default as indexy0AEa1X9lPMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/index.vue?macro=true";
import { default as matchnVYvz39ym8Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/match.vue?macro=true";
import { default as searchWq7B0wENBFMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/search.vue?macro=true";
import { default as submit_45distributionFeXwVKFaDdMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/submit-distribution.vue?macro=true";
import { default as generate_45errorzqZXanb9NtMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/generate-error.vue?macro=true";
import { default as applicantUTYmUR2H25Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/applicant.vue?macro=true";
import { default as email_45verification41HBW6f4s5Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/email-verification.vue?macro=true";
import { default as welcomec3xwyQLMscMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/welcome.vue?macro=true";
import { default as indexAZf4ZD2k7YMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/index.vue?macro=true";
import { default as authUasescsaJiMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/auth.vue?macro=true";
import { default as verificationnCUvr9zGzNMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/verification.vue?macro=true";
import { default as phone_45change_45errorfFkFPaHTKxMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/phone-change-error.vue?macro=true";
import { default as indexeCVguqtw5mMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/playground/index.vue?macro=true";
import { default as welcomeBr6rCAEoqXMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/preview/welcome.vue?macro=true";
import { default as privacy_45policyBnudBip3CkMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/privacy-policy.vue?macro=true";
import { default as applicant_45requestItjz5benstMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/applicant-request.vue?macro=true";
import { default as application_45submittedTv9cuHCxPjMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/application-submitted.vue?macro=true";
import { default as _91arid_93_45chat_45authpWuPQlOyToMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat-auth.vue?macro=true";
import { default as _91arid_93_45chat6tDkxYwBpsMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat.vue?macro=true";
import { default as _91step_93EoYGA1G9nZMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[step].vue?macro=true";
import { default as indexepryZImWbeMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/index.vue?macro=true";
import { default as assistance_45types0kBPlxWMUuMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-types.vue?macro=true";
import { default as client_45consent4tlsjrhuR4Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/client-consent.vue?macro=true";
import { default as document_45uploadGWfwmnAuZ0Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/document-upload.vue?macro=true";
import { default as email_45change_45verificationZfWCwCVyzqMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change-verification.vue?macro=true";
import { default as email_45changeI4WVFqlsi1Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change.vue?macro=true";
import { default as household_45incomeBor6BSWxnBMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/household-income.vue?macro=true";
import { default as household_45membersTZftaRUDpSMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/household-members.vue?macro=true";
import { default as meeting3qYV0TtCUEMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/meeting.vue?macro=true";
import { default as notice_45of_45privacy_45practices5UchwmpNywMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/notice-of-privacy-practices.vue?macro=true";
import { default as payee_45informationOrDuBGAjPmMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/payee-information.vue?macro=true";
import { default as personal_45details_45delete_45meOMjTy5xuWeMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-details-delete-me.vue?macro=true";
import { default as personal_45informationJUZvVuYlUOMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-information.vue?macro=true";
import { default as phone_45change_45verification3okHyQpkXIMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/phone-change-verification.vue?macro=true";
import { default as indexCVVNoWoSyxMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/index.vue?macro=true";
import { default as question_45_91index_93yl3532wXl0Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/question-[index].vue?macro=true";
import { default as welcometTBESa7RzYMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/welcome.vue?macro=true";
import { default as who_45applyingvYdBcpUchdMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/who-applying.vue?macro=true";
export default [
  {
    name: "appointment-id-details",
    path: "/appointment/:id()/details",
    meta: detailsrA0YqXnhFUMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/details.vue")
  },
  {
    name: "appointment-id",
    path: "/appointment/:id()",
    meta: indexV42NTTu3HZMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/index.vue")
  },
  {
    name: "appointment-disaster-id-app",
    path: "/appointment/disaster/:id()/:app()",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/disaster/[id]/[app].vue")
  },
  {
    name: "appointment-disaster-id",
    path: "/appointment/disaster/:id()",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/disaster/[id]/index.vue")
  },
  {
    name: "appointment-food-id-app",
    path: "/appointment/food/:id()/:app()",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/food/[id]/[app].vue")
  },
  {
    name: "appointment-food-id",
    path: "/appointment/food/:id()",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/food/[id]/index.vue")
  },
  {
    name: "appointment-list",
    path: "/appointment/list",
    meta: list3hQ8kOW9s8Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/list.vue")
  },
  {
    name: "eds-id-details",
    path: "/eds/:id()/details",
    meta: detailsI77458fw6KMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/details.vue")
  },
  {
    name: "eds-id-distribution-detail",
    path: "/eds/:id()/distribution-detail",
    meta: distribution_45detailJ0EdfLGwhJMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/distribution-detail.vue")
  },
  {
    name: "eds-id-distribution",
    path: "/eds/:id()/distribution",
    meta: distributionqNNK7qjN89Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/distribution.vue")
  },
  {
    name: "eds-id",
    path: "/eds/:id()",
    meta: indexNsyUd4ryc8Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/index.vue")
  },
  {
    name: "eds-id-match",
    path: "/eds/:id()/match",
    meta: match5IfnNruwtNMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/match.vue")
  },
  {
    name: "eds-id-search",
    path: "/eds/:id()/search",
    meta: searchX4CLnyxpOeMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/search.vue")
  },
  {
    name: "eds-id-submit-distribution",
    path: "/eds/:id()/submit-distribution",
    meta: submit_45distributionUijYuvPvFPMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/eds/[id]/submit-distribution.vue")
  },
  {
    name: "email-change-error",
    path: "/email-change-error",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/email-change-error.vue")
  },
  {
    name: "fp-id-details",
    path: "/fp/:id()/details",
    meta: detailsFEsPO1hbTyMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/details.vue")
  },
  {
    name: "fp-id-distribution-detail",
    path: "/fp/:id()/distribution-detail",
    meta: distribution_45detailF9b6cTF1WyMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution-detail.vue")
  },
  {
    name: "fp-id-distribution",
    path: "/fp/:id()/distribution",
    meta: distributionPQJOtyuW23Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution.vue")
  },
  {
    name: "fp-id",
    path: "/fp/:id()",
    meta: indexy0AEa1X9lPMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/index.vue")
  },
  {
    name: "fp-id-match",
    path: "/fp/:id()/match",
    meta: matchnVYvz39ym8Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/match.vue")
  },
  {
    name: "fp-id-search",
    path: "/fp/:id()/search",
    meta: searchWq7B0wENBFMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/search.vue")
  },
  {
    name: "fp-id-submit-distribution",
    path: "/fp/:id()/submit-distribution",
    meta: submit_45distributionFeXwVKFaDdMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/submit-distribution.vue")
  },
  {
    name: "generate-error",
    path: "/generate-error",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/generate-error.vue")
  },
  {
    name: "home-applicant",
    path: "/home/applicant",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/applicant.vue")
  },
  {
    name: "home-email-verification",
    path: "/home/email-verification",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/email-verification.vue")
  },
  {
    name: "home-welcome",
    path: "/home/welcome",
    meta: welcomec3xwyQLMscMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/welcome.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/index.vue")
  },
  {
    name: "payee-id-auth",
    path: "/payee/:id()/auth",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/auth.vue")
  },
  {
    name: "payee-id-verification",
    path: "/payee/:id()/verification",
    meta: verificationnCUvr9zGzNMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/verification.vue")
  },
  {
    name: "phone-change-error",
    path: "/phone-change-error",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/phone-change-error.vue")
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/playground/index.vue")
  },
  {
    name: "preview-welcome",
    path: "/preview/welcome",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/preview/welcome.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/privacy-policy.vue")
  },
  {
    name: "secure-applicant-request",
    path: "/secure/applicant-request",
    meta: applicant_45requestItjz5benstMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/applicant-request.vue")
  },
  {
    name: "secure-application-submitted",
    path: "/secure/application-submitted",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/application-submitted.vue")
  },
  {
    name: "secure-assistance-request-arid-chat-auth",
    path: "/secure/assistance-request/:arid()-chat-auth",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat-auth.vue")
  },
  {
    name: "secure-assistance-request-arid-chat",
    path: "/secure/assistance-request/:arid()-chat",
    meta: _91arid_93_45chat6tDkxYwBpsMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat.vue")
  },
  {
    name: "secure-assistance-request-step",
    path: "/secure/assistance-request/:step()",
    meta: _91step_93EoYGA1G9nZMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[step].vue")
  },
  {
    name: "secure-assistance-request",
    path: "/secure/assistance-request",
    meta: indexepryZImWbeMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/index.vue")
  },
  {
    name: "secure-assistance-types",
    path: "/secure/assistance-types",
    meta: assistance_45types0kBPlxWMUuMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-types.vue")
  },
  {
    name: "secure-client-consent",
    path: "/secure/client-consent",
    meta: client_45consent4tlsjrhuR4Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/client-consent.vue")
  },
  {
    name: "secure-document-upload",
    path: "/secure/document-upload",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/document-upload.vue")
  },
  {
    name: "secure-email-change-verification",
    path: "/secure/email-change-verification",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change-verification.vue")
  },
  {
    name: "secure-email-change",
    path: "/secure/email-change",
    meta: email_45changeI4WVFqlsi1Meta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change.vue")
  },
  {
    name: "secure-household-income",
    path: "/secure/household-income",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/household-income.vue")
  },
  {
    name: "secure-household-members",
    path: "/secure/household-members",
    meta: household_45membersTZftaRUDpSMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/household-members.vue")
  },
  {
    name: "secure-meeting",
    path: "/secure/meeting",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/meeting.vue")
  },
  {
    name: "secure-notice-of-privacy-practices",
    path: "/secure/notice-of-privacy-practices",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/notice-of-privacy-practices.vue")
  },
  {
    name: "secure-payee-information",
    path: "/secure/payee-information",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/payee-information.vue")
  },
  {
    name: "secure-personal-details-delete-me",
    path: "/secure/personal-details-delete-me",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-details-delete-me.vue")
  },
  {
    name: "secure-personal-information",
    path: "/secure/personal-information",
    meta: personal_45informationJUZvVuYlUOMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-information.vue")
  },
  {
    name: "secure-phone-change-verification",
    path: "/secure/phone-change-verification",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/phone-change-verification.vue")
  },
  {
    name: "secure-service-areas",
    path: "/secure/service-areas",
    meta: indexCVVNoWoSyxMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/index.vue")
  },
  {
    name: "secure-service-areas-question-index",
    path: "/secure/service-areas/question-:index()",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/question-[index].vue")
  },
  {
    name: "secure-service-areas-welcome",
    path: "/secure/service-areas/welcome",
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/welcome.vue")
  },
  {
    name: "secure-who-applying",
    path: "/secure/who-applying",
    meta: who_45applyingvYdBcpUchdMeta || {},
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/who-applying.vue")
  }
]