export enum EnumStepComponentType {
  Default = 'Default',
  HouseholdMember = 'HouseholdMember',
  Consent = 'Consent',
}

export enum EnumAssistanceRequestStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'Accepted',
  ApprovalPending = 'ApprovalPending',
  ReturnedToApplicant = 'ReturnedToApplicant',
  ApprovedClosed = 'ApprovedClosed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'ApprovedReceivedByFinance',
  ApprovedFundingSent = 'ApprovedFundingSent',
  Abandoned = 'Abandoned',
  UserDeleted = 'UserDeleted',
  Expired = 'Expired',
}
export enum EnumAssistanceRequestStatusUserFriendly {
  Draft = 'Draft',
  Submitted = 'Acceptance Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'Accepted',
  ApprovalPending = 'Approval Pending',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Closed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'Received by Finance',
  ApprovedFundingSent = 'Payment Sent',
  Abandoned = 'Abandoned',
  UserDeleted = 'User Deleted',
  Expired = 'Expired',
}

export const EnumAssistanceRequestStatusUserFriendlyJson: {[key: string]: string} = {
  Draft:  'Draft',
  Submitted:  'Acceptance Pending',
  Approved:  'Approved',
  Declined:  'Declined',
  Accepted:  'Accepted',
  ApprovalPending:  'Approval Pending',
  ReturnedToApplicant:  'Returned to Applicant',
  ApprovedClosed:  'Closed',
  Rescinded:  'Rescinded',
  ApprovedReceivedByFinance:  'Received by Finance',
  ApprovedFundingSent:  'Payment Sent',
  Abandoned:  'Abandoned',
  UserDeleted:  'User Deleted',
  Expired:  'Expired',
}

export enum EnumAppointmentStatus {
  Requested = 'Requested',
  CancelledByApplicant = 'CancelledByApplicant',
  CancelledByCaseworker = 'CancelledByCaseworker',
  Scheduled = 'Scheduled',
  Complete = 'Complete',
  NoShow = 'NoShow',
}

export enum EnumAppointmentStatusUserFriendly {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  CancelledByApplicant = 'Cancelled by Applicant',
  CancelledByCaseworker = 'Cancelled by Caseworker',
  Complete = 'Completed',
  NoShow = 'No Show',
}

export enum EnumAppointmentStatusUserFriendlyFood {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  CancelledByApplicant = 'Cancelled',
  CancelledByCaseworker = 'Cancelled',
  Complete = 'Completed',
  NoShow = 'No Show',
}

export enum EnumAppointmentStatusUserFriendlyDisaster {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  CancelledByApplicant = 'Cancelled',
  CancelledByCaseworker = 'Cancelled',
  Complete = 'Completed',
  NoShow = 'No Show',
}

// convert EnumAppointmentStatus to array with key value
export const EnumAppointmentStatusArray = Object.keys(EnumAppointmentStatusUserFriendly)
  .filter((_) => _ !== EnumAppointmentStatus.Requested)
  .map((key) => ({
    label: EnumAppointmentStatusUserFriendly[ key ],
    value: key,
  }));

export enum EnumAppointmentTypes {
  InPerson = 'inPerson',
  Virtual = 'virtual',
  Phone = 'phone',
}

export enum EnumAppointmentTypesUserFriendly {
  InPerson = 'In Person',
  Virtual = 'Video Conference',
  Phone = 'Telephone',
}

export enum EnumApplicationStatusUserFriendly {
  Draft = 'Draft',
  Submitted = 'Acceptance Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Accepted = 'In Progress',
  ApprovalPending = 'Approval Pending',
  ReturnedToApplicant = 'Returned to Applicant',
  ApprovedClosed = 'Closed',
  Rescinded = 'Rescinded',
  ApprovedReceivedByFinance = 'Received by Finance',
  ApprovedFundingSent = 'Payment Sent',
  Abandoned = 'Abandoned',
  Expired = 'Expired',
  UserDeleted = 'User Deleted',
}

// export enum EnumAssistanceRequestStatusUserFriendlyButtonLabels {
//   Approved = 'Approve',
//   Declined = 'Decline',
//   Accepted = 'Accept',
//   ApprovalPending = 'Submit for Approval',
//   ReturnedToApplicant = 'Return to Applicant',
//   ApprovedClosed = 'Finish and Close',
//   Rescinded = 'Rescind',
//   ApprovedReceivedByFinance = 'Mark as Received by Finance',
//   ApprovedFundingSent = 'Mark as Payment Sent',
//   Abandoned = 'Mark as Abandoned',
//   UserDeleted = 'Mark as User Deleted',
// }

export enum EnumLedgerTransactionType {
  RequestApprovalPending = 'RequestApprovalPending',
  RequestApproved = 'RequestApproved',
  RequestFunded = 'RequestFunded',
  RequestRescinded = 'RequestRescinded',
  FundDecreased = 'FundDecreased',
  FundIncreased = 'FundIncreased',
  FundingSourceDecreased = 'FundingSourceDecreased',
  FundingSourceIncreased = 'FundingSourceIncreased',
}

export enum EnumLedgerAction {
  InitialAllocation = 'InitialAllocation',
  AllocationAdjustment = 'AllocationAdjustment',
  FundsAssigned = 'FundsAssigned',
  FundsIssued = 'FundsIssued',
  FundsRescinded = 'FundsRescinded',
}

export enum EnumLedgerActionUserFriendly {
  InitialAllocation = 'Initial Allocation',
  AllocationAdjustment = 'Allocation Adjustment',
  FundsAssigned = 'Funds Assigned',
  FundsIssued = 'Funds Issued',
  FundsRescinded = 'Funds Rescinded',
}

export enum TsammPermission {
  SAHelpSupport = 1,
  SAHelpSpiritualNeedsCounselor = 2,
  SAHelpPaymentProcessing = 3,
  SAHelpCaseManagement = 4,
  SAHelpCaseManagementDirector = 5,

  // the following are legacy virtual, not in the 2.0 databases
  CanManageSAHelp = 262,
  SAHelpOversight = 553,
  DPISystemAdmin = 0,
}

export const TsammPermissionUserFriendly = new Map([
  [TsammPermission.CanManageSAHelp, 'Can Manage'],
  [TsammPermission.SAHelpSupport, 'Support'],
  [TsammPermission.SAHelpSpiritualNeedsCounselor, 'Spiritual Needs Counselor'],
  [TsammPermission.SAHelpPaymentProcessing, 'Payment Processing'],
  [TsammPermission.SAHelpCaseManagement, 'Case Management'],
  [TsammPermission.SAHelpCaseManagementDirector, 'Case Management Director'],
]);

export const StatusToPermission: Map<EnumAssistanceRequestStatus, Array<TsammPermission>> = new Map([
  [
    EnumAssistanceRequestStatus.Accepted,
    [
      TsammPermission.SAHelpSupport,
      TsammPermission.SAHelpCaseManagement,
      TsammPermission.SAHelpCaseManagementDirector,
      TsammPermission.CanManageSAHelp,
    ],
  ],
  [
    EnumAssistanceRequestStatus.ApprovalPending,
    [TsammPermission.SAHelpCaseManagement, TsammPermission.SAHelpCaseManagementDirector, TsammPermission.CanManageSAHelp],
  ],
  [EnumAssistanceRequestStatus.Approved, [TsammPermission.SAHelpCaseManagementDirector, TsammPermission.CanManageSAHelp]],
  [
    EnumAssistanceRequestStatus.ApprovedReceivedByFinance,
    [TsammPermission.SAHelpPaymentProcessing, TsammPermission.CanManageSAHelp],
  ],
  [
    EnumAssistanceRequestStatus.ApprovedFundingSent,
    [TsammPermission.SAHelpPaymentProcessing, TsammPermission.CanManageSAHelp],
  ],
  [
    EnumAssistanceRequestStatus.ApprovedClosed,
    [TsammPermission.SAHelpCaseManagement, TsammPermission.SAHelpCaseManagementDirector, TsammPermission.CanManageSAHelp],
  ],
  [
    EnumAssistanceRequestStatus.Declined,
    [
      TsammPermission.SAHelpSupport,
      TsammPermission.SAHelpCaseManagement,
      TsammPermission.SAHelpCaseManagementDirector,
      TsammPermission.CanManageSAHelp,
    ],
  ],
  [
    EnumAssistanceRequestStatus.ReturnedToApplicant,
    [
      TsammPermission.SAHelpSupport,
      TsammPermission.SAHelpCaseManagement,
      TsammPermission.SAHelpCaseManagementDirector,
      TsammPermission.CanManageSAHelp,
    ],
  ],
  [EnumAssistanceRequestStatus.Rescinded, [TsammPermission.SAHelpCaseManagementDirector, TsammPermission.CanManageSAHelp]],
]);

export function hasPermission(status: EnumAssistanceRequestStatus, permission: TsammPermission) {
  const permissions = StatusToPermission.get(status);
  return permissions && permissions.includes(permission);
}

export function hasAccess(status: EnumAssistanceRequestStatus, userPermissions: TsammPermission[]) {
  const tsammPermissions = new Set(Object.values(TsammPermission));
  const sahelpPermissions = userPermissions.filter((p) => tsammPermissions.has(p)).map((p) => p);
  for (const permission of sahelpPermissions) {
    if (hasPermission(status, permission)) {
      return true;
    }
  }
  return false;
}

export const stateOptions = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export enum EnumFundingSourceConfiguration {
  Ask = 'Ask',
  Excluded = 'Excluded',
  Required = 'Required',
  NotAsked = 'NotAsked',
}

export enum EnumFaqSection {
  Application = 'applications',
  Dashboard = 'dashboard',
  Appointment = 'appointments',
  Applicant = 'applicants',
  ServiceArea = 'service areas',
  FundingSource = 'funding sources',
  Fund = 'funds',
}

export enum EnumIntegrationType {
  Wellsky = 'Wellsky',
}

export enum EnumAssistanceTypeCategory {
  Housing = 'Housing',
  UtilGas = 'UtilGas',
  UtilElect = 'UtilElect',
  UtilWater = 'UtilWater',
}

export enum EnumGlobalTranslationKey {
  RestrictionPresentedMessage = 'restriction_presented_message',
}

export enum EnumFlagType {
  Chat = 'Chat',
  Payee = 'Payee',
}

export const wellskyGenderMapAr = { Male: 'Male', Female: 'Female', 'prefer not to respond': 'Data not collected' };
